import { conform, useForm } from '@conform-to/react';
import { getFieldsetConstraint, parse } from '@conform-to/zod';
import { z } from 'zod';
import {
	type DataFunctionArgs,
	json,
	type LoaderFunction,
	redirect,
} from '@remix-run/node';
import {
	Form,
	useActionData,
	useLoaderData,
	useSearchParams,
} from '@remix-run/react';
import { InputField, ErrorList } from '~/components/forms.tsx';
import Logo from '~/components/logo1.tsx';
import { Button } from '~/components/ui/button.tsx';
import { getRandomBackground } from '~/utils/scrape-backgrounds.server.ts';
import { EmailSchema } from '~/utils/user-validation.ts';
import { requireAnonymous } from '~/utils/session.server.ts';
import { validateCSRF } from '~/utils/csrf.server.ts';
import { checkHoneypot } from '~/utils/honeypot.server.ts';
import { AuthenticityTokenInput } from 'remix-utils/csrf/react';
import { HoneypotInputs } from 'remix-utils/honeypot/react';
import { crypto, safeRedirect, useIsPending } from '~/utils/misc.tsx';
import { prisma } from '~/utils/db.server.ts';

const LoginFormSchema = z.object({
	email: EmailSchema,
	redirectTo: z.string().optional(),
});

export async function action({ request }: DataFunctionArgs) {
	const formData = await request.formData();
	const redirectTo = formData.get('redirectTo') || '/me';
	await requireAnonymous(request);

	await validateCSRF(formData, request.headers);
	checkHoneypot(formData);

	const email = formData.get('email') as string;

	let url = new URL(
		`https://login.microsoftonline.com/${process.env.MICROSOFT_TENANT_ID}/oauth2/v2.0/authorize`,
	);

	const nonce = crypto.random();

	const session = await prisma.session.create({
		data: {
			store: { nonce, login_hint: email },
			expirationDate: new Date(new Date().getTime() + 10 * 60000),
		},
	});

	// required
	url.searchParams.set('client_id', process.env.MICROSOFT_CLIENT_ID || '');
	url.searchParams.set('response_type', 'id_token token');
	url.searchParams.set('response_mode', 'form_post');
	url.searchParams.set(
		'redirect_uri',
		process.env.MICROSOFT_REDIRECT_URI || '',
	);
	url.searchParams.set('scope', 'openid profile email');
	url.searchParams.set(
		'state',
		JSON.stringify({ sessionId: session.id, redirectTo }),
	);
	url.searchParams.set('nonce', nonce);
	url.searchParams.set('login_hint', email);

	return redirect(url.href);
}

export async function loader({ request }: DataFunctionArgs) {
	const url = new URL(request.url);
	console.log('redirect: ', url.searchParams.get('redirectTo'));
	const redirectTo = await safeRedirect(
		url.searchParams.get('redirectTo'),
		'/me',
	);
	await requireAnonymous(request, redirectTo);
	const background = await getRandomBackground();
	return json({ background });
}

export default function Login() {
	const data = useLoaderData<typeof loader>();
	const actionData = useActionData<typeof action>();
	const isPending = useIsPending();
	const [searchParams] = useSearchParams();
	const redirectTo = searchParams.get('redirectTo') || '/me';

	const [form, fields] = useForm({
		id: 'login-form',
		constraint: getFieldsetConstraint(LoginFormSchema),
		defaultValue: { redirectTo },

		onValidate({ formData }) {
			return parse(formData, { schema: LoginFormSchema });
		},
		shouldRevalidate: 'onBlur',
	});

	return (
		<div className="flex h-full flex-1">
			<div className="flex flex-1 flex-col overflow-hidden lg:max-w-xl">
				<div className="p-4 sm:p-6 lg:p-8 ">
					<Logo />
				</div>
				<div className="flex h-full flex-1 flex-col justify-center px-4 py-12 sm:px-6 lg:flex-none lg:px-20 xl:px-24 ">
					<div className="mx-auto w-full max-w-sm pb-40 sm:pb-44 lg:w-96 lg:pb-56">
						<div>
							<h2 className="mt-8 text-center text-5xl font-bold leading-tight tracking-tight sm:text-start">
								Welcome back
							</h2>
							<h3 className="text-center text-lg font-light text-muted-foreground sm:text-start">
								Please enter your details to log in
							</h3>
						</div>

						<div className="mt-8">
							<Form method="POST" {...form.props} className="space-y-6">
								<AuthenticityTokenInput />
								<HoneypotInputs />
								<InputField
									labelProps={{ children: 'Email address' }}
									inputProps={{
										...conform.input(fields.email),
										className: 'mt-2',
										autoFocus: true,
										type: 'email',
										autoCapitalize: 'none',
										autoComplete: 'email',
										autoCorrect: 'off',
									}}
								/>

								<input
									{...conform.input(fields.redirectTo, { type: 'hidden' })}
								/>
								<ErrorList errors={form.errors} id={form.errorId} />

								<div>
									<Button type="submit" className="w-full">
										Continue
									</Button>
								</div>
							</Form>
						</div>
					</div>
				</div>
			</div>
			<div className="relative hidden w-0 flex-1 overflow-hidden lg:block">
				<img
					className="absolute inset-0 h-full w-full animate-zoom object-cover"
					src={data.background}
					alt="Architectus Project Image"
				/>
			</div>
		</div>
	);
}
